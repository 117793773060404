.background-images {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.lozad {
  opacity: 0;
}

.page-wrapper {
  position: relative;
  width: 100vw;
}

.bg-img {
  position: absolute;
  object-fit: cover;
}

.dog-and-nyfw .bg-img {
  height: 50vh;
  max-height: 30vw;
}

.dog-and-nyfw .bg-img:first-child {
  top: 0;
  left: 0;
}

.dog-and-nyfw .bg-img:last-child {
  bottom: 0;
  right: 0;
}

.yeezy .bg-img {
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.nyfw-row, .piggyback {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nyfw-row .bg-img {
  position: relative;
  width: 20vw;
  object-fit: contain;
  margin-top: -10vh;
}

.piggyback .bg-img {
  right: 10vw;
  height: 60vh;
}

@media only screen and (max-width: 767px) {
  .dog-and-nyfw {
    display: flex;
    justify-content: center;
  }

  .dog-and-nyfw .bg-img {
    height: auto;
    max-height: none;
    width: 75vw;
  }

  .dog-and-nyfw .bg-img:first-child {
    top: 5vw;
  }

  .dog-and-nyfw .bg-img:last-child {
    bottom: 5vw;
  }

  .nyfw-row .bg-img {
    margin-top: -35vh;
    width: 33vw;
  }

  .nyfw-row .bg-img:nth-child(4),
  .nyfw-row .bg-img:nth-child(5) {
    display: none;
  }

  .piggyback {
    align-items: flex-start;
  }

  .piggyback .bg-img {
    top: 5vh;
    right: auto;
    height: 45vh;
  }
}

@media only screen and (max-width: 575px) {
  .dog-and-nyfw .bg-img {
    width: 80vw;
  }

  .dog-and-nyfw .bg-img:first-child {
    top: 10vw;
    left: auto;
  }

  .dog-and-nyfw .bg-img:last-child {
    bottom: 15vw;
    right: auto;
  }

  .yeezy .bg-img {
    top: -10vw;
    left: -60vw;
    width: auto;
    height: 110vh;
  }
}

@keyframes background-fade {
  0%, 85% { opacity: 0 }
  20%, 70% { opacity: 1 }
}
