.homepage-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.name img:nth-child(1) {
  height: 110px;
  padding-right: 200px;
}

.name img:nth-child(2) {
  height: 90px;
  padding-left: 200px;
  margin-top: 20px;
}

.name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 35px;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  nav {
    flex-direction: column-reverse;
  }

  .homepage-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .name img,
  .name img:nth-child(1),
  .name img:nth-child(2) {
    height: auto;
    padding: 0;
    width: 55vw;
  }

  .name img:nth-child(1) {
    margin: 0 -30px -15px;
    width: 60vw;
  }
}

@media only screen and (max-width: 575px) {
  .name img:nth-child(1) {
    margin: 0 -15px -15px;
  }
}

.copy-email {
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 5px;
  border: 1px solid transparent;
}

.copy-email:hover {
  border-color: #fff;
}

.copy-email.clicking {
  background-color: #fff;
  color: #000;
  animation: 0.2s design-link-flash infinite;
}

@media only screen and (max-width: 767px) {
  .copy-email {
    margin-top: 15px;
  }
}

@keyframes design-link-flash {
  from {
    color: #fff;
    background-color: transparent;
  }

  to {
    color: #31474A;
    background-color: #fff;
  }
}

.copy-email.clicking p {
  background-image: url('./assets/copy-icon-clicked.svg');
}

.copy-email p {
  background-image: url('./assets/copy-icon.svg');
  background-repeat: no-repeat;
  padding-left: 25px;
  margin: 0;
}

input {
  width: 180px;
  font-size: 25px;
  outline: none;
  border: none;
  background-color: transparent;
  color: #fff;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  padding: 5px 0;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

form.nonono {
  animation: 0.12s shake infinite;
}

@keyframes shake {
  0% { transform: translate(-10px); }
	100% { transform: translate(10px); }
}
