body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  body {
    min-height: 85vh;
  }
}

h1 {
  font-weight: 400;
}

body,
h1,
input::placeholder {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-size: 17px;
  letter-spacing: 1px;
}

img {
  pointer-events: none;
  user-select: none;
}

textarea {
  position: absolute;
  top: -9999999px;
  left: -9999999px;
}
